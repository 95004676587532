import React from 'react';
import {Backdrop, Box, Button, CircularProgress, ClickAwayListener, Dialog, DialogActions, DialogContent, DialogTitle, Grid, Stack, Typography} from "@mui/material";
import { AdaptiveQuestionnaire } from '@cambianrepo/cambianreact';
import { AdaptiveQuestionnaireReport } from '@cambianrepo/cambianreact';
import { AdaptiveQuestionnaireResponses } from '@cambianrepo/cambianreact';
import {useMutation, useQuery} from "@apollo/client";
import {useTranslation} from "react-i18next";

import {
    getAuthFromSessionStorage,
    getSelectedOrganizationId,
    getSelectedQuestionnaireResponseId,
    getSelectedQuestionnaireId,
    getSelectedTaskId,
    clearSelectedQuestionnaireId,
    setSelectedOrganizationId,
    setSelectedQuestionnaireResponseId,
    setSelectedQuestionnaireAssignmentRequestId,
    getSelectedQuestionnaireAssignmentRequestId,
    setSelectedTaskId,
    setSelectedQuestionnaireId
} from "../../../utility";

import { useAuthContext } from "navigator/security";

import Loader from "../../../Loader";

import {START_ASSIGNED_QUESTIONNAIRE_REQUEST, NEXT_ITEM_REQUEST} from "../../../../gqlMutators";
import {GET_QUESTIONNAIRE_RESPONSE_SUMMARIES} from "../../../../gqlQueries";

function Empty() {
    return (
        <div>This component is empty</div>
    );
}

function AnswerMixtureCAT(props) {
    const { currentUser } = useAuthContext();
    const { t } = useTranslation();
    const [component, setComponent] = React.useState(<Empty/>);
    const [openQuestionnaireAction, setOpenQuestionnaireAction] = React.useState({});
    const {selectedViewCallback} = props;
    const [responseSaving, setResponseSaving] = React.useState(false);
    const [showBusyRetrievingQuestionnaire, setShowBusyRetrievingQuestionnaire] = React.useState(false);
    const [nextItemRequestGraphQL] = useMutation(NEXT_ITEM_REQUEST);
    const [startQuestionnaireRequestGraphQL] = useMutation(START_ASSIGNED_QUESTIONNAIRE_REQUEST);
    const [openSaveErrorDialog, setOpenSaveErrorDialog] = React.useState(false);

    const { refetch } = useQuery(GET_QUESTIONNAIRE_RESPONSE_SUMMARIES, {
        variables: {
            "CambianUserId": currentUser.userReference.globalId,
            "PatientId": currentUser.patientReference.idValue
        }
    });

    const updateComponent = (component) => {
        setComponent(component);
    }

    let organizationIdParameter;
    let questionnaireIdParameter;
    let selectedQuestionnaireResponseIdParameter;
    let taskIdParameter;
    let questionnaireAssignmentRequestIdParameter;

    if (getSelectedOrganizationId() !== null) {
        organizationIdParameter = getSelectedOrganizationId();
    }

    if (getSelectedQuestionnaireId() !== null) {
        questionnaireIdParameter = getSelectedQuestionnaireId();
    }

    if (getSelectedQuestionnaireResponseId() !== null) {
        selectedQuestionnaireResponseIdParameter = getSelectedQuestionnaireResponseId();
    }

    if (getSelectedTaskId() !== null) {
        taskIdParameter = getSelectedTaskId();
    }

    if (getSelectedQuestionnaireAssignmentRequestId() !== null) {
        questionnaireAssignmentRequestIdParameter = getSelectedQuestionnaireAssignmentRequestId();
    }

    const [questionnaireData, setQuestionnaireData] = React.useState(JSON.parse(selectedQuestionnaireResponseIdParameter));
    const [catResult, setCatResult] = React.useState(null);
    const [isFirstQuestion, setIsFirstQuestion] = React.useState(true);
    // const [answeredQuestions, setAnsweredQuestions] = React.useState({});

    React.useEffect(() => {
        if (questionnaireData != undefined && Object.keys(questionnaireData).length) {
            updateComponent(
                <Stack gap={2}>
                    <AdaptiveQuestionnaire catQuestionnaire={questionnaireData}
                                           catResult={catResult}
                                           catQuestionnaireIds={{ idValue: '', globalId: questionnaireIdParameter}}
                                           handleQuestionnaireCallback={handleQuestionnaireCallback}
                                           patientId={currentUser.patientReference.idValue}
                                           organizationId={organizationIdParameter}
                                           userId={currentUser.userReference.globalId}
                                           taskId={taskIdParameter}
                                           />

                    {/* <AdaptiveQuestionnaireReport catResult={catResult} /> */}
                    {/* {questionnaireData && answeredQuestions.length > 0 &&
                        <AdaptiveQuestionnaireResponses questionnaireResponses={answeredQuestions} />
                    } */}

                </Stack>
            )
        } else {
            updateComponent(<Empty />)
        }
    }, [questionnaireData, isFirstQuestion, /*answeredQuestions*/]);

    const makeNextAndSaveRequest = (questionnaireData) => {
        setShowBusyRetrievingQuestionnaire(true);
        const URL = process.env.REACT_APP_AUTH_SERVER_URL + `cat/irt/${questionnaireData.questionnaireId.globalId}/itemresponses/saveAndNext?organizationId=${organizationIdParameter}`;

        const accessToken = "Bearer " + getAuthFromSessionStorage()?.accessToken;
        
        const options = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': accessToken
            },
            body: JSON.stringify(questionnaireData),
        };
        setIsFirstQuestion(false);

        fetch(URL, options)
        .then((res) => res.json())
        .then((data) => {
            setQuestionnaireData(data.question);
            setCatResult(data.thetaSEHistoricalData)
            // getAnsweredQuestions(questionnaireData);
            setShowBusyRetrievingQuestionnaire(false);
        });
    };

    // const getAnsweredQuestions = (data) => {
    //     const URL = process.env.REACT_APP_AUTH_SERVER_URL + `cat/irt/${data.questionnaireResponseId.idValue}/answered`;

    //     const accessToken = "Bearer " + getAuthFromSessionStorage()?.accessToken;
        
    //     const options = {
    //         method: 'GET',
    //         headers: {
    //             'Content-Type': 'application/json',
    //             'Authorization': accessToken
    //         }
    //     };

    //     fetch(URL, options)
    //     .then((res) => res.json())
    //     .then((data) => {
    //         setAnsweredQuestions(data);
    //     });
    // };

    const finalizeCATRequest = (data) => {
        setResponseSaving(true);
        const URL = process.env.REACT_APP_AUTH_SERVER_URL + `questionnaireresponses/${data.questionnaireResponseId.idValue}/finalize?orgType=Location&orgId=${organizationIdParameter}`;

        const accessToken = "Bearer " + getAuthFromSessionStorage()?.accessToken;

        const options = {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': accessToken
            },
            body: JSON.stringify(data),
        };
        setIsFirstQuestion(false);

        fetch(URL, options)
        .then((response) => response.json())
        .then((data) => {
            if (data.successful === false) {
                setResponseSaving(false);
                setOpenSaveErrorDialog(true);

            } else {
                if (data.questionnaireAction !== undefined && data.questionnaireAction !== null) {
                    refetch().then(()=> {
                        setResponseSaving(false);
                        setOpenQuestionnaireAction(data.questionnaireAction);
                    })
                } else {
                    refetch().then(() => {
                        selectedViewCallback('QuestionnaireListView');
                    });
                }
           }

       })
       .catch((error) => {
           console.log("ERROR: " + error);
       });
    }

    const handleQuestionnaireCallback = (questionnaireData, isCATQuestionAvailable) => {
        if (isCATQuestionAvailable) {
            makeNextAndSaveRequest(questionnaireData);
        } else {
            finalizeCATRequest(questionnaireData);
        }
    }

    const handleCloseQuestionnaireAction = () => {
        setOpenQuestionnaireAction();
        selectedViewCallback("QuestionnaireListView");
    }

    const handleContinueQuestionnaireAction = () => {
        let questionnaireAction = openQuestionnaireAction;
        setOpenQuestionnaireAction();
        console.log(questionnaireAction);
        if(questionnaireAction.nextQuestionnaireType == 'MixtureCATItemBank') {
            answerCATQuestionnaireActionQuestionnaire(questionnaireAction.organizationId, questionnaireAction.nextQuestionnaireId, questionnaireAction.taskId);
        } else {
            answerQuestionnaireActionQuestionnaire(questionnaireAction.organizationId, questionnaireAction.nextQuestionnaireId, questionnaireAction.taskId);
        }
    }

    const answerQuestionnaireActionQuestionnaire = (organizationId, questionnareId, taskId) => {
        setResponseSaving(true);
        startQuestionnaireRequestGraphQL({
            variables: {
                "OrganizationId": organizationId,
                "QuestionnaireId": questionnareId,
                "TaskId": taskId
            }
        }).then(result => {
            console.log(result);
            let questionnaireResponseId = result.data.startQuestionnaire;
            refetch().then(() => {
                continueQuestionnaire(organizationId, questionnaireResponseId, taskId);
            });
        }).catch(error => {
            console.log(error); // TODO - need to get a error dialog up
        });
    }

    // Needed to trigger CAT questionnaire actions and all three parameters will be present
    const answerCATQuestionnaireActionQuestionnaire = (organizationId, questionnareId, taskId, questionnaireResponseId, requestId) => {
        setResponseSaving(true);

        console.log("nextItemRequestGraphQL - organization: " + organizationId + " questionnaireId: " + questionnareId + " taskId: " + taskId);
        nextItemRequestGraphQL({
            variables: {
                "OrganizationId": organizationId,
                "QuestionnaireId": questionnareId,
                "TaskId": taskId
            }
        }).then(result => {
            console.log(result.data.nextItem);
            questionnaireResponseId = result.data.nextItem;
            continueMixtureCAT(organizationId, questionnaireResponseId, requestId, questionnareId, taskId);
        }).catch(error => {
            console.log(error); // TODO - need to get a error dialog up
        });
    }

    // Needed to trigger questionnaire actions
    const continueQuestionnaire = (organizationId, questionnaireResponseId, taskId) => {
        console.log('continue questionnaire response: ' + questionnaireResponseId)
        clearSelectedQuestionnaireId();
        setSelectedOrganizationId(organizationId);
        setSelectedQuestionnaireResponseId(questionnaireResponseId);
        if (taskId !== null) {
            setSelectedQuestionnaireAssignmentRequestId(taskId); // need this to daisy chain the next questionnaire action
        }
        selectedViewCallback('ContinueQuestionnaireView');
        window.location.replace(process.env.REACT_APP_WEBSITE_CONTEXT);
    }

    const continueMixtureCAT = (organizationId, questionnaireResponseId, requestId, questionnareId, task) => {
        console.log('continue MixtureCAT: ' + questionnaireResponseId)
        clearSelectedQuestionnaireId();
        setSelectedTaskId(task);
        setSelectedQuestionnaireId(questionnareId);
        setSelectedOrganizationId(organizationId);
        setSelectedQuestionnaireResponseId(questionnaireResponseId);
        setSelectedQuestionnaireAssignmentRequestId(requestId);
        selectedViewCallback('AnswerMixtureCATView');
        window.location.replace(process.env.REACT_APP_WEBSITE_CONTEXT);
    }

    if (responseSaving) {
        return (
            <Loader active={true} message={"Saving questionnaire responses. Please wait."}/>
        )
    }

    const handleCloseSaveError = () => {
        setOpenSaveErrorDialog(false);
    }

    return (
        <Grid container direction="row"
             sx={{
                 '& .MuiTextField-root': {m: 1}, '& .css-5n8ohs-MuiGrid-root': {flexBasis: '100%', maxWidth: '100%'}, '& .MuiTypography-root': {m: '5px'}, p: 2, height: '100%'
             }}>
           <Box component={Grid} item xs={0} sm={1} md={2} display={{xs: "hidden", md: "block"}}/>
           <Box component={Grid} item xs={9} display={{xs: "block"}}>
                    <div>
                        {component}
                    </div>
            </Box>
            <Box component={Grid} item xs={12} sm={10} md={8} display={{xs: "block"}}>
                <ClickAwayListener onClickAway={handleCloseQuestionnaireAction}>
                    <Dialog open={openQuestionnaireAction !== undefined && openQuestionnaireAction.nextQuestionnaireId !== undefined}
                            onClose={handleCloseQuestionnaireAction}>
                        <DialogTitle>New Questionnaire To Complete</DialogTitle>
                        <DialogContent>
                            <Box>
                                <Typography id="questionnaireSaved" sx={{m: 2}}>
                                    {t("You have been assigned a new ")}{openQuestionnaireAction.nextQuestionnaireName}{t(" questionnaire by ")}{openQuestionnaireAction.organizationName}{t(". Click the 'Launch Now' button to complete it.")}
                                </Typography>
                            </Box>
                        </DialogContent>
                        <DialogActions>
                            {openQuestionnaireAction.completeLater ? (<Button variant="outlined" onClick={handleCloseQuestionnaireAction}>{t("Do It Later")}</Button>) : ''}
                            <Button variant="contained" 
                                    onClick={handleContinueQuestionnaireAction}>
                               {t("Launch Now")}
                            </Button>
                        </DialogActions>
                    </Dialog>
                </ClickAwayListener>

                <ClickAwayListener onClickAway={handleCloseSaveError}>
                    <Dialog open={openSaveErrorDialog}
                            onClose={handleCloseSaveError}>
                        <DialogTitle>{t("Failure when saving Questionnaire")}</DialogTitle>
                        <DialogContent>
                            <Box>
                                <Typography id="questionnaireSaveFail" sx={{m: 2}}>
                                    {t("The attempt to save the questionnaire has failed.  Please contact technical support.")}
                                </Typography>
                            </Box>
                        </DialogContent>
                        <DialogActions>
                            <Button variant="contained"
                                    onClick={handleCloseSaveError}>
                               {t("Close")}
                            </Button>
                        </DialogActions>
                    </Dialog>
                </ClickAwayListener>

            </Box>
            <Box component={Grid} item xs={0} sm={1} md={2} display={{xs: "hidden", md: "block"}}/>
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={showBusyRetrievingQuestionnaire}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
       </Grid>
   );
}

export default AnswerMixtureCAT;