import React, {useRef} from "react";
import OrganizationEducation from "../information/OrganizationEducation";
import TwoColumnPage from "../../TwoColumnPage";
import {
    getSelectedArticleId,
    getSelectedView,
    setSelectedArticleId,
    setSelectedOrganizationId
} from "../../utility";
import HomeView from "./home/HomeView";
import ConnectionList from "./networkConnection/ConnectionList";
import OrganizationView from "./organization/OrganizationView";
import ViewArticle from "./viewArticle/ViewArticle";
import QuestionnaireList from "./questionnaireList/QuestionnaireList";
import ViewQuestionnaireResults from "./viewQuestionnaireResults/ViewQuestionnaireResults";
import AnswerQuestionnaire from "./questionnaire/AnswerQuestionnaire";
import AnswerMixtureCAT from "./questionnaire/AnswerMixtureCAT";
import {useNavigate, useSearchParams} from "react-router-dom";
import {Paper} from "@mui/material";

function MainPage(props) {
    const {selectedViewCallback} = props;
    const [organizationId, setOrganizationId] = React.useState();
    const listInnerRef = useRef();
    const [updatedScrollPosition, setUpdatedScrollPosition] = React.useState(0);
    let [searchParams] = useSearchParams();
    let navigate = useNavigate();

    const selectedOrganizationCallback = (organizationId) => {
        setSelectedOrganizationId(organizationId);
        setOrganizationId(organizationId);
    }

    let articleIdQueryParameter = searchParams.get("articleId");
    if (articleIdQueryParameter !== null) {
        setSelectedArticleId(articleIdQueryParameter);
        selectedViewCallback('ArticleView');
        navigate('/');
    }

    const selectedArticleCallback = (articleId) => {
        console.log('selectedArticleCallback: ' + articleId);
        setSelectedArticleId(articleId);
        navigate('/');
    }

    const resolveLeftColumnView = () => {
        let currentView = getSelectedView();
        if (currentView === 'HomeView') {
            console.log('HomeView')
            return( <HomeView selectedViewCallback={selectedViewCallback}
                              updatedScrollPosition={updatedScrollPosition}/>);

        } else if (currentView === 'NetworkConnectionView') {
            console.log('NetworkConnectionView')
            return( <ConnectionList organizationId={organizationId}
                                    selectedViewCallback={selectedViewCallback}
                                    selectedOrganizationCallback={selectedOrganizationCallback}/>);

         } else if (currentView === 'OrganizationAboutView') {
            console.log('OrganizationAboutView')
            return( <OrganizationView selectedViewCallback={selectedViewCallback}
                                      orgView={currentView}
                                      organizationId={organizationId}
                                      selectedArticleCallback={selectedArticleCallback} /> );

        } else if (currentView === 'OrganizationResourceView') {
            console.log('OrganizationResourceView')
            return( <OrganizationView selectedViewCallback={selectedViewCallback}
                                      orgView={currentView}
                                      organizationId={organizationId}
                                      selectedArticleCallback={selectedArticleCallback} />);

        } else if (currentView === 'ArticleView') {
            console.log('ArticleView')
            return (<ViewArticle articleId={getSelectedArticleId()} selectedViewCallback={selectedViewCallback} />);

        } else if (currentView === 'QuestionnaireListView') {
            console.log('QuestionnaireListView')
            return( <QuestionnaireList selectedViewCallback={selectedViewCallback} /> );

        } else {
            return(<></>);
        }
    }


    const resolveMainPageView = () => {
        let currentView = getSelectedView();
        if (currentView === 'ViewQuestionnaireResultsView') {
            console.log('ViewQuestionnaireResultsView')
            return( <ViewQuestionnaireResults selectedViewCallback={selectedViewCallback}/> );

        } else if (currentView === 'ContinueQuestionnaireView') {
            console.log('ContinueQuestionnaireView')
            return( <AnswerQuestionnaire selectedViewCallback={selectedViewCallback} /> );
        } else if (currentView === 'AnswerMixtureCATView') {
            console.log('AnswerMixtureCATView')
            return( <AnswerMixtureCAT selectedViewCallback={selectedViewCallback} />)
        }

        let leftColumnView =  <div>
            { resolveLeftColumnView() }
        </div>

        return (
            <TwoColumnPage leftColumn={leftColumnView}
                           rightColumn={<OrganizationEducation organizationId={organizationId}
                                                               selectedViewCallback={selectedViewCallback}
                                                               selectedArticleCallback={selectedArticleCallback} />} />
        );
    }

    const handleOnScroll = (event) => {
        if (listInnerRef.current) {
            const {scrollTop, scrollHeight, clientHeight} = listInnerRef.current;
            if ((scrollTop + clientHeight) >= (scrollHeight-clientHeight)) {
                setUpdatedScrollPosition(scrollHeight);
            }
        }
    };

    return (
       <Paper sx={{height: '100%', borderRadius: '0px', border: '0px', backgroundColor: 'rgb(245, 248, 250)'}}>
           <div style={{ overflowY: 'auto', height: '100%' }}
                onScroll={(event) => handleOnScroll(event)}
                ref={listInnerRef}>
               {resolveMainPageView()}
           </div>
       </Paper>
    );
}

export default MainPage;